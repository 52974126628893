import { Button, Card, TextField, Typography } from '@mui/material'
import React from 'react'
import logo from '../assessts/logo.png'

import { useParams } from 'react-router';

import { url } from '../globalurl';
import { Snackbar, Alert } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { isMobile } from 'react-device-detect';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import Dialog from '@mui/material/Dialog';

import axios from "axios";

let sw = window.innerWidth

const withRouter = WrappedComponent => props => {
    const params = useParams();
    // etc... other react-router-dom v6 hooks
  
    return (
      <WrappedComponent
        {...props}
        params={params}
        // etc...
      />
    );
};

class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchCode : "",
            productDetails : {},
            templateDetails : {},
            productUrl : "",
            alertMsg : null,
            alert : false,
            severity : null,
            isLoading : false,
            imgFull : false,
        }
    }

    componentDidMount = async() => {
        if (this.props.params.id) {
            await this.setState({searchCode : this.props.params.id})
            this.handleSearch()
        }
        
    }

    handleSearch = () => {
        let data = {
            bar_code : this.state.searchCode
        }
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            }
        }
        axios.post(`${url}/products`, data, config).then((response) => {
            if (response.status === 200) return response.data
        }).then((result) => {
            if (result.status && result.products.length !== 0) {
                this.handleImageDownload(result.products[0].productPhotoPath)
                this.setState({productDetails : result.products[0], templateDetails : result.templates[0], isLoading:true})
            } else {
                this.setState({alert : true, alertMsg : "No Product Found", severity: "error"})
            }
        })
    }

    handleImageDownload = async (photoPath) => {
        let image = ""
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
        }
        let formData = new FormData()
        formData.append('photo_name', photoPath)
        await axios({
            url : `http://app.piar.ee/api/download`,
            data : formData,
            method : "POST",
            headers : config.headers,
            responseType : "blob"
        }).then((response) => {
            if (response.status === 200) {
                image = URL.createObjectURL(new Blob([response.data], {type: 'image/jpg'}));
                this.setState({productUrl : image, isLoading : false})
            }
        })
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', padding:20}}>
                <Card sx={{
                    width : isMobile ? "95%" : "80%",
                    minHeight: 300,
                    borderRadius: 15/4,
                    background: "#fff",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    display:'flex',
                }}>
                    <div style={{flex: isMobile ? 0.05 : 0.1}}></div>
                    {/* This is main development div */}
                    <div style={{flex: isMobile ? 0.9 : 0.8, display:'flex', flexDirection:'column', padding: isMobile ? "10px 5px" : "40px 5px", gap:25}}>
                        <img src={logo} alt="logo" width={ Object.keys(this.state.productDetails).length !== 0 ? 140 : 200} />
                        <div style={{flex:1, display:'flex', gap:20, flexDirection : isMobile ? "column" : "row"}}>
                            <TextField 
                                onChange={(e) => this.setState({searchCode : e.target.value}) }
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.handleSearch()
                                    }
                                }}
                                value={this.state.searchCode}
                                placeholder={"Enter Item Number or Bar Code or QR Code"} 
                                style={isMobile ? {} : {flex:0.8}} />
                            <Button 
                                onClick={this.handleSearch}
                                variant="contained" 
                                style={{flex:0.2, height:56}}>Search</Button>
                            {Object.keys(this.state.productDetails).length !==0 ? 
                                <Button 
                                    onClick={() => this.setState({productDetails : {}, templateDetails:{}, productUrl : "", searchCode:""})}
                                    variant="contained" 
                                    style={{flex:0.1, height:56}}>Clear</Button> : null
                            }
                        </div>
                        {/* Render Search details */}
                        {Object.keys(this.state.productDetails).length !==0 ? 
                        <div>
                            <div style={{display:'flex', gap:10, flexDirection:'column'}}>
                                <div style={{display:'flex',  alignItems: 'stretch', flexDirection: isMobile ? "column" : "row", gap : isMobile ? 5 : 20}}>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                        <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>S/N</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.templateDetails.batchCode} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                    
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>QR Code</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.productDetails.qrCode} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex',  alignItems:'stretch', flexDirection: isMobile ? "column" : "row", gap : isMobile ? 5 : 20}}>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Name</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.templateDetails.productName} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                    
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1 }
                                             style={{maxWidth:132}}>Bar Code</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.productDetails.barCode} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex',  alignItems: 'stretch', flexDirection: isMobile ? "column" : "row", gap : isMobile ? 5 : 20}}>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Description</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                multiline
                                                rows={3}
                                                value={this.state.templateDetails.description} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                    
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132} }>Gross Weight</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.templateDetails.grossWeight} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex',  alignItems: 'stretch', flexDirection: isMobile ? "column" : "row", gap : isMobile ? 5 : 20}}>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Measurements</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.templateDetails.measurements} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                    
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Label Date</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={(new Date(this.state.productDetails.entryTime*1000)).toLocaleDateString()} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex',  alignItems: 'stretch', flexDirection: isMobile ? "column" : "row", gap : isMobile ? 5 : 20}}>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Made In</Typography>
                                        <div style={ {width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.templateDetails.madeIn} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                    
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Item Number</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.productDetails.barCode} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex',  alignItems:'stretch', flexDirection: isMobile ? "column" : "row", gap : isMobile ? 5 : 20}}>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}>
                                         <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={1}
                                             style={{maxWidth:132}}>Pcs/Ctn</Typography>
                                        <div style={{width: "50%"}}>
                                            <TextField 
                                                disabled
                                                value={this.state.templateDetails.ctnPcs} 
                                                variant="outlined" 
                                                 />
                                        </div>
                                    </div>
                                    <div style={{display:'flex', flex:1, gap: isMobile ? 5 : 10, alignItems: 'center'}}></div>
                                </div>
                                <Card 
                                    onClick={() => {
                                            this.setState({imgFull : true})}}
                                    style={{maxWidth:'max-content', maxHeight:600, padding:10,  display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                                    <Typography flex={1} style={{alignSelf:'flex-start'}}>Product Image</Typography>
                                    <div 
                                        onClick={() => {
                                            this.setState({imgFull : true})}}
                                        style={{width:40, height:40, backgroundColor:"white", justifyContent:'center', cursor:'pointer',
                                                alignItems:'center', borderRadius:5,
                                                position:'absolute', marginTop:34, marginRight:4, display:'flex',}}>
                                        <AspectRatioIcon />
                                    </div>
                                    {this.state.isLoading ? <CircularProgress /> :
                                    <img src={this.state.productUrl} alt="product-image" width={isMobile ? "100%" : sw/2} style={{borderRadius:5}} />}
                                </Card>
                            </div>
                            <div></div>
                        </div> : null}
                    </div>
                    <div style={{flex: isMobile ? 0.05 : 0.1}}></div>
                </Card>
                <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.setState({alert : false})}>
                    <Alert elevation={6} onClose={() => this.setState({alert : false})} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    fullWidth
                    maxWidth={"md"}
                    open={this.state.imgFull}
                    onClose={() => {this.setState({imgFull : false})}}
                >
                    <img src={this.state.productUrl} alt="img-full" style={{width:'auto', borderRadius:5}} />
                </Dialog>
            </div>
        )
    }
}

export default withRouter(Main)