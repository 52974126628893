import logo from './logo.svg';
import './App.css';
import Main from './component/main'
import RenderRoutes from './routes';

function App() {
  return (
    <RenderRoutes />
  );
}

export default App;
