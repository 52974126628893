import React, {useState, useEffect} from "react";
import {HashRouter as Router, Route, Routes, Navigate  } from "react-router-dom";
import Main from "./component/main";

export default function RenderRoutes(props) {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Main/>} >
                    <Route path={"/:id"} element={<Main />} />
                </Route>
            </Routes>
        </Router>
    )
}